import BaseService from '@/services/base/base.service';
import axios from 'axios';

class MobileAppService extends BaseService {
  public async registerUser(payload: MobileAppRegistrationRequest) {
    return await axios.post(
      `${super.clientsApiBaseUrl()}/mobile/customer/register`,
      payload,
    );
  }
}

export const mobileAppService = new MobileAppService();

export interface MobileAppRegistrationRequest {
  email: string;
  password: string;
  name: string;
  surname: string;
  language: string;
  privacy: boolean;
  merchantId: string;
  mobileHash: string;
  privacy1?: boolean;
  privacy2?: boolean;
  privacy3?: boolean;
}
