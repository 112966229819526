export class CustomerInfo {
  email: string | undefined = undefined;
  name: string | undefined = undefined;
  surname: string | undefined = undefined;
  mediaHash: string | undefined = undefined;
  mediaSerial: string | undefined = undefined;
  mediaHwId: string | undefined = undefined;
  mediaType: number | undefined = undefined;
  merchantId: string | undefined = undefined;
  language: string | undefined = undefined;

  public static make(data?: Partial<CustomerInfo>): any {
    return Object.assign(new this(), data);
  }
}
