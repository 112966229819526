import i18n from '@/i18n';
import { strNoWhitespaces } from '@/utils/string.utils';

export const emailRules = (v: string): boolean => {
  return !!v && RegExp(/^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,30})+$/).test(v);
};

export const passwordRules = (v: string): boolean => {
  let count = 0;

  if (!!v && v.length >= 8 && v.length <= 16) {
    if (v.match('[0-9]')) {
      count++;
    }
    if (v.match('[a-z]')) {
      count++;
    }
    if (v.match('[A-Z]')) {
      count++;
    }
    if (/[@#$%^&*_+=[\]{}|\\:',?/`~"();.-]/.test(v)) {
      count++;
    }
  }

  return !!v && count >= 3;
};

export const regularRules = (): unknown => {
  return [
    (v: string) => !!v || i18n.t('errors.required'),
    (v: string) => v.length >= 8 || i18n.t('errors.min-password'),
  ];
};

export const minimumOfZeroRules = (): unknown => {
  return [
    (v: number) => !!v || i18n.t('errors.required'),
    (v: number) => v >= 0 || i18n.t('errors.min-zero'),
  ];
};

export const greaterThanZeroRules = (): unknown => {
  return [
    (v: number) => !!v || i18n.t('errors.required'),
    (v: number) => v > 0 || i18n.t('errors.min-more-zero'),
  ];
};

export const minValueRules = (minValue: number): unknown => {
  return [
    (v: number) => v >= minValue || i18n.t('errors.min-of-value', { minValue }),
  ];
};

export const maxValueRules = (maxValue: number): unknown => {
  return [
    (v: number) => v <= maxValue || i18n.t('errors.max-of-value', { maxValue }),
  ];
};

export const onlyNumberRules = (): unknown => {
  const pattern = /^(?=.*[0-9])/;
  return (v: string) =>
    (v.length > 0 ? pattern.test(v) : true) || i18n.t('errors.invalid-number');
};

export const requiredRules = (v: string, noWhitespaces?: boolean): boolean => {
  // return [(v: string) => !!v || i18n.t('errors.required')];
  return !!(noWhitespaces ? strNoWhitespaces(v) : v);
};

export const minLengthRule = (
  v: string,
  minLength: number,
  noWhitespaces?: boolean,
): boolean => (noWhitespaces ? strNoWhitespaces(v) : v).length >= minLength;

export const maxLengthRules = (v: string, maxLength: number): boolean =>
  v.length <= maxLength;
