





































import { Component, Vue } from 'vue-property-decorator';
import { emailRules } from '@/utils/input-rules.utils';
import { isNullOrWhiteSpace } from '@glitchedmob/isnullorempty';
import { MediasGetters } from '@/store/modules/medias/enums';
import { MediaStatus } from '@/models/medias/mediaStatus';
import { urlQueryService } from '@/services/common/urlquery.service';

@Component
export default class AuthPanelLogin extends Vue {
  protected qrCode: string | undefined = '';

  // not removed because the idea is to pass it
  protected email = '';

  protected cssMail = '';
  protected credentialError = false;

  public created(): void {
    this.qrCode = urlQueryService.getQRCodeQuery()?.hash;
  }

  public async login(): Promise<void> {
    await this.$msal.signIn(this.email, this.qrCode as string);
  }

  public get hasCustomer(): boolean {
    const media = this.$store.getters[
      MediasGetters.GetMediaStatusToAssociate
    ] as MediaStatus;
    if (media == null) {
      return false;
    }
    return !!media.customerId;
  }

  assertValidEmail(): void {
    this.cssMail = !emailRules(this.email) ? 'is-invalid' : '';
  }

  addQRIfExists(request: Request): Request {
    if (isNullOrWhiteSpace(this.qrCode)) {
      return request;
    }

    return Object.assign({ ...request }, { mediaHash: this.qrCode });
  }
}
