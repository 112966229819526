/**
 * This method returns the string itself, or an empty string if the value received is not a valid string.
 *
 * @param str - The string.
 * @returns The string adjusted.
 */
export const strUndefinedOrNullToEmpty = (str: string): string => {
  return str == null ? '' : str;
};

/**
 * This method strips the received string of all the eventual whitespaces.
 *
 * @param str - The string.
 * @returns The string adjusted.
 */
export const strNoWhitespaces = (str: string): string => {
  return strUndefinedOrNullToEmpty(str).replace(/\s+/g, '');
};

/**
 * This method keeps only the specified params in the url,
 * stripping all other eventual params that are present in the url query.
 *
 * @param urlStr - The url in string format.
 * @param baseUrl - Base url, optional argument. Needed if the url specified is relative.
 * @param paramsToKeep - The params to keep.
 * @returns The url adjusted.
 */
export const keepOnlySpecifiedParamsInUrl = (
  urlStr: string,
  baseUrl?: string,
  ...paramsToKeep: string[]
): string => {
  if (baseUrl != null) {
    baseUrl = urlIsRelative(baseUrl)
      ? window.location.origin + baseUrl
      : baseUrl;
  }
  try {
    const url: URL = new URL(urlStr, baseUrl);
    Array.from(url.searchParams.keys())
      .filter((k) => !paramsToKeep.includes(k))
      .forEach((mfd) => url.searchParams.delete(mfd));
    return url.toString();
  } catch (err) {
    console.log(err);
  }
  return urlStr;
};

/**
 * Indicates if the url specified is absolute. This method is non-case sensitive and protocol-agnostic.
 *
 * @param urlStr - The url in string format.
 * @returns True if the url received is absolute.
 */
export const urlIsAbsolute = (urlStr: string): boolean => {
  return new RegExp('^(?:[a-z]+:)?//', 'i').test(urlStr);
};

/**
 * Indicates if the url specified is relative. This method is non-case sensitive and protocol-agnostic.
 *
 * @param urlStr - The url in string format.
 * @returns True if the url received is relative.
 */
export const urlIsRelative = (urlStr: string): boolean => {
  return !urlIsAbsolute(urlStr);
};

/**
 * This method concatenates the strings received with the eventual delimiter interpositioned.
 *
 * @param delimiter - The delimiter, optional argument.
 * @param strings - The strings to concat.
 * @returns The concatenation of the strings received.
 */
export const strConcat = (delimiter?: string, ...strings: string[]): string => {
  return strings.join(delimiter ? delimiter : '');
};
