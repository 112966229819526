























































































import { AuthActions } from '@/store/modules/auth/enums';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MerchantConfig } from '@/models/config/merchantConfig';
import AuthPanelLogin from '@/views/auth/AuthPanelLogin.vue';
import AuthPanelRegister from '@/views/auth/AuthPanelRegister.vue';

@Component({
  components: {
    'ck-auth-panel-login': AuthPanelLogin,
    'ck-auth-panel-register': AuthPanelRegister,
  },
})
export default class AuthPanel extends Vue {
  @Prop() merchantConfig!: MerchantConfig;
  @Prop() withRegister!: boolean;
  @Prop({ default: false }) onlyRegister!: boolean;
  private signupComplete = false;

  protected switchOption = true;

  public created(): void {
    this.$store.dispatch(AuthActions.SetAwaitingB2CResponse, false);
  }

  public toggleLoginMenu(_switch: boolean): void {
    this.switchOption = _switch;
  }

  private signupSuccess(): void {
    this.signupComplete = true;
  }
}
