





































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { CustomerInfo } from '@/models/customers/customerinfo';
import { MerchantConfig } from '@/models/config/merchantConfig';
import { customerService } from '@/services/customers/customer.service';
import {
  MobileAppRegistrationRequest,
  mobileAppService,
} from '@/services/mobileapp/mobileapp.service';
import {
  emailRules,
  maxLengthRules,
  minLengthRule,
  passwordRules,
  requiredRules,
} from '@/utils/input-rules.utils';
import { urlQueryService } from '@/services/common/urlquery.service';
import { Routes } from '@/router/routes';
import { MediasGetters } from '@/store/modules/medias/enums';
import { MediaStatus } from '@/models/medias/mediaStatus';
import { CommonGetters } from '@/store/modules/common/enums';
import { isNotNullOrEmpty } from '@glitchedmob/isnullorempty';

@Component
export default class AuthPanelRegister extends Vue {
  @Prop() merchantConfig!: MerchantConfig;

  private isValidField = new Array(7).fill(true);
  public isValidForm = false;
  public mobileSignupSuccess = false;

  private nonRequiredField = ['privacy1', 'privacy2', 'privacy3', 'password'];

  public userInfo = CustomerInfo.make({
    mediaHash: urlQueryService.getQRCodeQuery()?.hash,
    merchantId: this.merchantConfig.merchantId,
    mediaType: 0,
    mediaHwId: '',
    mediaSerial: '',
    language: this.$store.getters[CommonGetters.Language]?.toLowerCase(),
  });

  public get hasCustomer(): boolean {
    const media = this.$store.getters[
      MediasGetters.GetMediaStatusToAssociate
    ] as MediaStatus;
    if (media == null) {
      return false;
    }
    return !!media.customerId;
  }

  mounted(): void {
    if (this.isMobileAppRegistration()) {
      this.nonRequiredField.push('mediaHash');
    }
    this.userInfo.email = '';
  }

  async registerUser(): Promise<void> {
    this.$spinner.showSpinner();
    if (this.isMobileAppRegistration()) {
      this.userInfo.mobileHash =
        urlQueryService.getMobileAppRegistrationQuery()?.hash;
      const registrationRequest = this.mapUserInfoToMobileAppRequest(
        this.userInfo,
      );
      await mobileAppService
        .registerUser(registrationRequest)
        .then(() => {
          this.mobileSignupSuccess = true;
          this.$emit('success');
        })
        .catch((err) => {
          const errorMsg = err.response?.data?.Detail;
          if (errorMsg) {
            this.$toaster.showError(errorMsg);
          } else {
            this.$toaster.showError('register.registration-error', true);
          }
        })
        .finally(() => this.$spinner.removeSpinner());
    } else {
      await customerService
        .createProfile(this.userInfo)
        .then(() => {
          this.$toaster.showSuccess('register.registration-success', true);
          this.$router.push({ name: Routes.Home });
        })
        .catch((err) => {
          const errorMsg = err.response?.data?.Detail;
          if (errorMsg) {
            this.$toaster.showError(errorMsg);
          } else {
            this.$toaster.showError('register.registration-error', true);
          }
        })
        .finally(() => this.$spinner.removeSpinner());
    }
  }

  isRequiredField = (field: string): boolean =>
    !(this.nonRequiredField.indexOf(field) > -1);

  validateForm(): void {
    let isPasswordMatch =
      this.userInfo.password == this.userInfo.confirmPassword;

    this.isValidField = [
      requiredRules(this.userInfo.name, true) &&
        maxLengthRules(this.userInfo.name, 100),
      requiredRules(this.userInfo.surname, true) &&
        maxLengthRules(this.userInfo.surname, 100),
      emailRules(this.userInfo.email) &&
        maxLengthRules(this.userInfo.email, 100),
      passwordRules(this.userInfo.password),
      passwordRules(this.userInfo.confirmPassword) && isPasswordMatch,
      this.userInfo.privacy == true,
      minLengthRule(this.userInfo.language, 2, true) &&
        maxLengthRules(this.userInfo.language, 2),
    ];

    this.isValidForm =
      Object.values(this.isValidField).every((x) => !!x) &&
      Object.keys(this.userInfo)
        .filter((x) => this.isRequiredField(x))
        .map((x) => this.userInfo[x])
        .every((x) => x != undefined && x !== false);
  }

  private isMobileAppRegistration(): boolean {
    const mobileAppRegistrationAction =
      urlQueryService.getMobileAppRegistrationQuery();
    return isNotNullOrEmpty(mobileAppRegistrationAction?.hash);
  }

  private mapUserInfoToMobileAppRequest(
    userInfo: any,
  ): MobileAppRegistrationRequest {
    return {
      email: userInfo.email?.trim(),
      password: userInfo.password,
      name: userInfo.name?.trim(),
      surname: userInfo.surname?.trim(),
      language: userInfo.language,
      privacy: userInfo.privacy,
      merchantId: userInfo.merchantId,
      mobileHash: userInfo.mobileHash,
      privacy1: userInfo.privacy1,
      privacy2: userInfo.privacy2,
      privacy3: userInfo.privacy3,
    };
  }
}
